import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryFlagIcon from "./CountryFlagIcon";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";

const SelectOptionWithIcon = (props) => {
  return (
    <>
      {props.data.value?.paiIso2 ? (
        <CountryFlagIcon countryCode={props.data.value.paiIso2} className="mr-2" />
      ) : (
        <FontAwesomeIcon icon={faGlobeAmericas} className="mr-2" />
      )}
      {props.data.label}
    </>
  );
};

export default SelectOptionWithIcon;
