import axios from "axios";
import { getApiUrl, buildRequestHeaders } from "./Api";
import { CATALOGUE_URL_CONSTANTS } from "./Constants";

export const getCountries = async (accessToken) => {
  const tenant = localStorage.getItem("pai");

  return await axios.get(`${getApiUrl()}${CATALOGUE_URL_CONSTANTS.getCountryListFilterCatalogue}`, {
    headers: buildRequestHeaders(accessToken),
  });
};
