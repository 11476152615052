const CountryFlagIcon = ({ countryCode, className }) => {
  return (
    <img
      className={className}
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
      alt="flag"
    />
  );
};

export default CountryFlagIcon;
