import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { GLOBAL_CONSTANTS } from "utils/Constants";
import { AUTH0_SCOPES } from "utils/Auth0Helper";
import store from "store/store";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={GLOBAL_CONSTANTS.API_OAUTH_DOMAIN}
      clientId={GLOBAL_CONSTANTS.API_OAUTH_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "b2c",
        scope: `${AUTH0_SCOPES.join(" ")}`,
      }}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </Provider>
);
